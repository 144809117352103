const isDevelopVersion = false;

const twistoPayBaseRoute = isDevelopVersion ? 'https://twisto-5589.rostiapp.cz/' : 'https://twistopay.liffstudio.com/';
const twistoPayTexts = {
	cs: {
		CantPayWithTwisto: 'Omlouváme se, ale tuto objednávku nelze uzavřít přes Twisto Pay, zvolte proto jinačí způsob platby. Děkujeme',
		GoodsNow: 'Zboží hned, plaťte za 30 dní.',
		HowTo: 'Jak na to ?',
		OrderCompleting: 'Probíhá dokončování objednávky...',
	},
	sk: {
		CantPayWithTwisto: 'Ospravedlňujeme sa, ale túto objednávku nemožno uzavrieť cez Twist Pay, vyberte preto inakší spôsob platby. Ďakujeme',
		GoodsNow: 'Tovar hneď, plaťte za 30 dní.',
		HowTo: 'Ako na to?',
		OrderCompleting: 'Prebieha dokončovanie objednávky...',
	},
	en: {
		CantPayWithTwisto: 'We are sorry, but this order cannot be closed via Twisto Pay, so choose another payment method. Thank you',
		GoodsNow: 'Goods now, pay for 30 days.',
		HowTo: 'How to do it ?',
		OrderCompleting: 'The order is being completed...',
	},
	de: {
		CantPayWithTwisto: 'Es tut uns leid, aber diese Bestellung kann nicht über Twisto Pay geschlossen werden. Wählen Sie daher eine andere Zahlungsmethode. Vielen Dank',
		GoodsNow: 'Waren jetzt 30 Tage bezahlen.',
		HowTo: 'Wie es geht ?',
		OrderCompleting: 'Die Bestellung wird abgeschlossen...',
	},
	pl: {
		CantPayWithTwisto: 'Przepraszamy, ale tego zamówienia nie można zamknąć za pomocą usługi Twisto Pay, dlatego wybierz inną metodę płatności. Dziękuję Ci',
		GoodsNow: 'Towar teraz, zapłać za 30 dni.',
		HowTo: 'Jak to zrobić ?',
		OrderCompleting: 'Zamówienie jest w trakcie realizacji...',
	},
};

const currencyRoundingEnum = {
	NONE: 'none',
	MATH: 'math',
	UP: 'up',
	DOWN: 'down',
}

// Funkce, ktera zpracuje config
const initPaymentConfig = () => {
	//Temporary
	fetch(`${twistoPayBaseRoute}config/${getShoptetDataLayer('projectId')}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
	.then(response => response.json())
	.then(data => {
		// Banners
		if (data.banners) {
			// Banner header
			if (data.banners.header.isVisible) {
				const body = document.querySelector('body');

				if (body) {
					body.insertAdjacentHTML('afterbegin', `
						<div class="twistoPay twistoPayHeaderBanner">
							<img src="${data.banners.header.imgLink}" alt="TwistoPay" />
						</div>
					`);
				}
			}

			// Banner footer
			if (data.banners.footer.isVisible) {
				const footer = document.querySelector('footer .footer-bottom');

				if (footer) {
					footer.insertAdjacentHTML('beforebegin', `
						<div class="container twistoPay twistoPayFooterBanner">
							<img src="${data.banners.footer.imgLink}" alt="TwistoPay" />
						</div>
					`);
				}
			}

			// Product detail
			if (data.banners.productDetail.isVisible) {
				const productDetail = document.querySelector('.type-detail .p-info-wrapper');

				if (productDetail) {
					const language = getShoptetDataLayer().language;
					const languageTexts = twistoPayTexts[language];

					let goodsNowText = '';
					let howToText = '';

					if (languageTexts) {
						goodsNowText = twistoPayTexts[language].GoodsNow;
						howToText = twistoPayTexts[language].HowTo;
					} else {
						goodsNowText = twistoPayTexts.en.GoodsNow;
						howToText = twistoPayTexts.en.HowTo;
					}

					productDetail.insertAdjacentHTML('beforeend', `
						<div class="twistoPay twistoProductDetailBanner">
							<div class="twistoProductDetailBannerLogo">
								<img src="${data.images.logo.imgLink}" alt="TwistoPay" />
							</div>
							<div class="twistoProductDetailBannerText">
								${goodsNowText} <span class="twistoProductDetailBannerButton" onclick="document.querySelector('.twistoProductDetailBannerOverlay').classList.add('is-open')">${howToText}</span>
							</div>
						</div>
						<div class="twistoPay twistoProductDetailBannerOverlay" onclick="document.querySelector('.twistoProductDetailBannerOverlay').classList.remove('is-open')">
							<img class="twistoProductDetailBannerOverlayImgDesktop" src="${data.banners.productDetail.imgLinkDesktop}" alt="TwistoPay" />
							<img class="twistoProductDetailBannerOverlayImgMobile" src="${data.banners.productDetail.imgLinkMobile}" alt="TwistoPay" />
						</div>
					`);
				}
			}
		} else {
			console.warn('TwistoPay - Config banners missing')
		}

		if (data.currencies) {
			window.localStorage.setItem('twisto.currencies', JSON.stringify(data.currencies));
		} else {
			console.warn('TwistoPay - Config currencies missing')
		}
	});
};

// Funkce ktera zjisti zorbazeni error hlasky s duvodem odmitnuti twisto pay
const initPaymentChoosePageErrorMessage = () => {
	const billingChoosePage = getShoptetDataLayer().pageType === 'billingAndShipping'; 
	const errorMessage = window.localStorage.getItem('twisto.paymentChoose.error');

	if (billingChoosePage && errorMessage) {
		showMessage(errorMessage, 'error');
		window.localStorage.removeItem('twisto.paymentChoose.error');
	}
};

// Funkce ktera zajisti otevreni banneru twista
const openTwistoPayProductDetailBanner = () => {
	const twistoProductDetailBannerOverlay = document.querySelector('.twistoProductDetailBannerOverlay');

	if (twistoProductDetailBannerOverlay) {
		twistoProductDetailBannerOverlay.classList.add('is-open');
	} else {
		console.warn('TwistoPay - Product detail banner overlay is missing')
	}
};

// Funkce ktera zajisti zavření banneru twista
const closeTwistoPayProductDetailBanner = () => {
	const twistoProductDetailBannerOverlay = document.querySelector('.twistoProductDetailBannerOverlay');

	if (twistoProductDetailBannerOverlay) {
	   twistoProductDetailBannerOverlay.classList.remove('is-open');
	} else {
	   console.warn('TwistoPay - Product detail banner overlay is missing')
	}
};

// Funkce, pro výběr zaokrouhlení pro danou cenu
const getCurrencyRounding = () => {
	const currencies = JSON.parse(window.localStorage.getItem('twisto.currencies'));
	const currency = getShoptetDataLayer().currency;

	if (currencies) {
		return currencies.find(c => c.code === currency).rounding;
	} else {
		return null;
	}
}

// Funkce pro zaokrouhleni ceny
const roundCurrency = (price) => {
	const currencyRounding = getCurrencyRounding();

	switch(currencyRounding) {
		// Matematické zaokrouhlování
		case currencyRoundingEnum.MATH:
			price = Math.round(price);
			break;

		// Zaokrouhlení nahoru
		case currencyRoundingEnum.UP:
			price = Math.ceil(price);
			break;

		// Zaokrouhlení dolů
		case currencyRoundingEnum.DOWN:
			price = Math.floor(price);
			break;
	}

	return price;
}

// Funkce ktera zjisti zvolenou moznost dopravy
const initShipping = () => {
	document.addEventListener('ShoptetShippingMethodUpdated', function () {
		const isShippingChoosePage = getShoptetDataLayer().pageType === 'billingAndShipping'; 

		if (isShippingChoosePage) {
			const shippingChoosePage = document.querySelector('body');
			const shippingOptionsBlock = shippingChoosePage.querySelector('#order-shipping-methods');

			if (shippingOptionsBlock) {
				const shippingOptions = shippingOptionsBlock.querySelectorAll('input[type="radio"]:not([disabled])');

				if (shippingOptions && shippingOptions.length > 0) {
					Array.prototype.forEach.call(shippingOptions, function (shippingOption) {
						if (shippingOption.checked) {
							let shippingName = null;
							let shippingPrice = 0;
							let shippingVat = 0;
							let shippingPriceWoVat = 0;
							let shippingId = Number(shippingOption.getAttribute('value'));

							const shippingOptionParent = shippingOption.parentElement;

							if (shippingOptionParent) {
								const shippingOptionNameElement = shippingOptionParent.querySelector('.shipping-billing-name');
								const shippingOptionInputElement = shippingOptionParent.querySelector('[data-relations]');
								const shippingId = shippingOptionInputElement.value;
								const shippingOptionPriceElement = document.querySelector(`[data-shipping-price-id='${shippingId}']`);

								if (shippingOptionNameElement && shippingOptionPriceElement) {
									shippingName = shippingOptionNameElement.innerHTML;
									shippingPrice = Number(shippingOptionPriceElement.getAttribute('data-shipping-price'));

									shippingPriceWoVat = Number(shippingOptionPriceElement.getAttribute('data-shipping-price-wv'));

									if (shippingPrice !== 0 && shippingPriceWoVat !== 0) {
										shippingVat = Number((((shippingPrice - shippingPriceWoVat) * 100) / shippingPrice).toFixed(0));
									}
								}
							}

							if (!shippingName) {
								shippingName = shippingOption.getAttribute('data-guid');
							}

							if (!shippingName) {
								console.warn('TwistoPay - No shipping name detected');
							} else {
								window.localStorage.setItem('twisto.shipping', JSON.stringify(
									{
										id: shippingId,
										name: shippingName,
										price: shippingPriceWoVat,
										vat: 0,
									}
								));
							}

							shippingOption.click();
						}
					});
				}
			}
		}
	});
};


// Funkce která zjisti zvolenou moznost platby
const initBilling = () => {
	const isBillingChoosePage = getShoptetDataLayer().pageType === 'billingAndShipping'; 

	document.addEventListener('ShoptetBillingMethodUpdated', function () {
		if (isBillingChoosePage) {
			window.localStorage.removeItem('twisto.isSelected');
			const billingChoosePage = document.querySelector('body');
			const billingOptionsBlock = billingChoosePage.querySelector('#order-billing-methods');

			if (billingOptionsBlock) {
				const billingOptions = billingOptionsBlock.querySelectorAll('input[type="radio"]:not([disabled])');

				if (billingOptions && billingOptions.length > 0) {
					Array.prototype.forEach.call(billingOptions, function (billingOption) {
						if (billingOption.checked) {
							let billingName = null;
							let billingPrice = 0;
							let billingVat = 0;
							let billingId = Number(billingOption.getAttribute('value'));

							const billingOptionParent = billingOption.parentElement;

							if (billingOptionParent) {
								const billingOptionNameElement = billingOptionParent.querySelector('.shipping-billing-name');
								const billingOptionInputElement = billingOptionParent.querySelector('[data-payment-type]');
								const billingId = billingOptionInputElement.value;
								const billingOptionPriceElement = document.querySelector(`[data-billing-price-id='${billingId}']`);

								if (billingOptionNameElement && billingOptionPriceElement) {
									billingName = billingOptionNameElement.innerHTML;
									billingPrice = Number(billingOptionPriceElement.getAttribute('data-billing-price'));
									const billingPriceWoVat = Number(billingOptionPriceElement.getAttribute('data-billing-price-wv'));

									if (billingPrice !== 0 && billingPriceWoVat !== 0) {
										billingVat = Number((((billingPrice - billingPriceWoVat) * 100) / billingPrice).toFixed(0));
									}
								}

								// Kontrola jestli je vybrané twisto pay
								const isTwistoPaySelected = billingOption.getAttribute('data-payment-type') === 'twistopay';

								if (isTwistoPaySelected || isDevelopVersion) {
									console.log('TwistoPay is selected');

									window.localStorage.setItem('twisto.isSelected', true);
									window.LogRocket && window.LogRocket.init('3ycbxn/shoptet-twistopay');
								}
							}

							if (!billingName) {
								billingName = billingOption.getAttribute('data-guid');
							}

							if (!billingName) {
								console.warn('TwistoPay - No billing name detected');
							} else {
								window.localStorage.setItem('twisto.billing', JSON.stringify(
									{
										id: billingId,
										name: billingName,
										price: billingPrice,
										vat: 0,
									}
								));
							}



							billingOption.click();

							// Zapamatování si url adresy, kde se meni zpusob platby
							window.localStorage.setItem('twisto.paymentChoose.url', window.location.href);
						}
					});
				}
			}
		}
	});

};

const productsSettings = [];


// Funkce ktera natahne nastaveni k jednotlivym produktum
const initLoadProductSettings = () => {
	const isSendOrderPage = getShoptetDataLayer().pageType === 'customerDetails'; 

	if (isSendOrderPage) {
		const cartProducts = getShoptetDataLayer().cart;
		const shopId = getShoptetDataLayer('projectId');

		if (cartProducts && cartProducts.length && cartProducts.length > 0) {
			Array.prototype.forEach.call(cartProducts, function (cartProduct) {
				fetch(`${twistoPayBaseRoute}product/code/${shopId}?code=${encodeURIComponent(cartProduct.code)}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					},
				})
				.then(response => response.json())
				.then(data => {
					if (data) {
						productsSettings.push({
							code: cartProduct.code,
							vatRate: Number(data.vatRate),
							price: Number(data.price),
							name: data.productName,
						});
					}
				})
			});
		} else {
			console.warn ('TwistoPay - no goods in cart');
		}
	}
}


// --> Zakomentování z důvodu nekompatibility s TwistoPay doplňkem
// let allDiscountCupons = null;

// // Funkce ktera natahne vsechny dostupne slevove kupony
// const initLoadDiscountCupons = () => {
//const isSendOrderPage = getShoptetDataLayer().pageType === 'customerDetails'; 


// 	if (isSendOrderPage) {
// 		const shopId = getShoptetDataLayer('projectId');

// 		fetch(`${twistoPayBaseRoute}coupons/${shopId}`, {
// 			method: 'GET',
// 			headers: {
// 				'Content-Type': 'application/json'
// 			},
// 		})
// 		.then(response => response.json())
// 		.then(data => {
// 			if (data) {

// 				allDiscountCupons = data.data.coupons;
// 			}
// 		})

// 	}
// }

let isSendOrderAvailable = false;
let isSendOrderProcesing = false;
let isTwistoCheckDone = false;

// --> Zakomentováno z důvodů zrušení funkce
// const toggleDisableInputs = (sendOrderFormInputs, disable) => {
// 	if (sendOrderFormInputs) {
// 		sendOrderFormInputs.forEach((input) => {
// 			disable ? input.setAttribute('disabled', true) : input.removeAttribute('disabled');
// 		});
// 	}
// }

// Funkce ktera zajisti akci po kliku na "odeslat objednavku"
const initSendOrderCheck = () => {
	const isSendOrderPage = getShoptetDataLayer().pageType === 'customerDetails'; 

	if (isSendOrderPage) {
		const sendOrderPage = document.querySelector('body');
		const sendOrderForm = sendOrderPage.querySelector('#order-form');
		const sendOrderSubmitBtn = sendOrderPage.querySelector('#submit-order');

		if (window.localStorage.getItem('twisto.isSelected')) {
			window.LogRocket && window.LogRocket.init('3ycbxn/shoptet-twistopay');
		}

		if (sendOrderForm) {
			window.addEventListener('keydown', (event) => {
				if (event.key === 'Enter') {
					event.preventDefault();    //stop form from submitting
				}
			});

			sendOrderForm.addEventListener('submit', (event) => {
				if (window.localStorage.getItem('twisto.isSelected') && !isTwistoCheckDone) {
					event.preventDefault();    //stop form from submitting

					const sendOrderSubmitBtnOldHtml = sendOrderSubmitBtn.innerHTML;
					sendOrderSubmitBtn.classList.add('disabled');
					sendOrderSubmitBtn.style.pointerEvents = 'none';
					sendOrderSubmitBtn.innerHTML = 'Vyřizuji...';

					const formData = $(sendOrderForm).serializeArray();
					const formDataFormatted = {};

					Array.prototype.forEach.call(formData, function (item) {
						formDataFormatted[item.name] = item.value;
					});

					formDataFormatted.billCountry = sendOrderForm.querySelector(`#billCountryId option[value="${formDataFormatted.billCountryId}"]`);
					formDataFormatted.phoneCode = JSON.parse(formDataFormatted.phoneCode);

					//BillingAddress
					const billingAddress = {
						fullName: formDataFormatted.billFullName,
						street: formDataFormatted.billStreet,
						city: formDataFormatted.billCity,
						zip: formDataFormatted.billZip,
						countryCode: formDataFormatted.billCountry.dataset.code,
						phone: formDataFormatted.phoneCode.phoneCode + formDataFormatted.phone,
					};

					//DeliveryAddress
					const deliveryAddress = {
						fullName: formDataFormatted.deliveryFullName,
						street: formDataFormatted.deliveryStreet,
						city: formDataFormatted.deliveryCity,
						zip: formDataFormatted.deliveryZip,
						countryCode: formDataFormatted.billCountry.dataset.code,
						phone: formDataFormatted.phoneCode.phoneCode + formDataFormatted.phone,
					}

					const cartProducts = getShoptetDataLayer().cart;
					const productsList = getShoptetProductsList();

					if (!cartProducts) {
						console.warn('TwistoPay - No products detected');
						return;
					}

					//Zakaznik
					const customer = {
						email: formDataFormatted.email,
						fullName: formDataFormatted.billFullName,
						cin: formDataFormatted.companyId || null,
						dic: formDataFormatted.vatId || null,
						company: formDataFormatted.billCompany || '',
					};

					//Celková částka DPH + Produkty
					const items = [];
					let totalPriceProductWihoutDiscount = 0
					let totalPriceVat = 0;

					const shoptetTotalPriceVat = getShoptetDataLayer().cartInfo.getNoBillingShippingPrice.withVat;

					// Sleva
					const discountCupons = getShoptetDataLayer().cartInfo.discountCoupon;
					let isDiscountCupon = discountCupons && !Array.isArray(discountCupons);

					Array.prototype.forEach.call(cartProducts, function (cartProduct) {
						const productSettings = productsSettings.find((item) => {
							return cartProduct.code == item.code;
						});

						if (!productSettings) {
							console.error('TwistoPay - Cannot find product settings');
							return;
						}

						let currentProductPrice = 0;
						for(product in productsList) {
							if (productsList.hasOwnProperty(product)) {
								const currentProduct = productsList[product];

								if (currentProduct.content_ids[0] === cartProduct.code) {
									currentProductPrice = currentProduct.value;
									break;
								}
							}
						}
						
						// Kontrola, zda na daném produktu je sleva
						const hasSamePrice = productSettings.price === currentProductPrice;

						if (!isDiscountCupon && !hasSamePrice) {
							isDiscountCupon = true;
						}

						items.push({
							itemType: 0,
							name: productSettings.name,
							productId: cartProduct.code,
							amount: cartProduct.quantity,
							withVat: productSettings.price * cartProduct.quantity,
							vat: productSettings.vatRate,
						});

						// Počítání celkové ceny produktů se slevou
						totalPriceVat += currentProductPrice * cartProduct.quantity;
						// Počítání celkové ceny produktů bez slevy
						totalPriceProductWihoutDiscount += productSettings.price * cartProduct.quantity;
					});


					if (isDiscountCupon) {
						let discountAmount = totalPriceProductWihoutDiscount - totalPriceVat;

						if (totalPriceVat != shoptetTotalPriceVat) {
							discountAmount += totalPriceVat - shoptetTotalPriceVat;
							totalPriceVat = shoptetTotalPriceVat;
						}

						discountAmount = discountAmount.toFixed(2)

						items.push({
							itemType: 4,
							name: discountCupons.code ? discountCupons.code : 'sleva',
							productId: discountCupons.id ? discountCupons.id : 'sleva',
							amount: 1,
							withVat: -Math.abs(discountAmount),
							vat: 0,
						});

						console.log('discountAmount', discountAmount);
					}


					// Doprava
					const shipping = JSON.parse(window.localStorage.getItem('twisto.shipping'));

					if (shipping) {
						items.push({
							itemType: 1,
							name: shipping.name,
							productId: shipping.id,
							amount: 1,
							withVat: Number(shipping.price.toFixed(2)),
							vat: shipping.vat,
						});

						totalPriceVat += shipping.price;
					} else {
						console.warn('TwistoPay - No shipping session detected');
					}

					// Platba
					const billing = JSON.parse(window.localStorage.getItem('twisto.billing'));

					if (billing) {
						items.push({
							itemType: 2,
							name: billing.name,
							productId: 'twistopay',
							amount: 1,
							withVat: Number(billing.price.toFixed(2)),
							vat: billing.vat,
						});

						totalPriceVat += billing.price;
					} else {
						console.warn('TwistoPay - No billing session detected');
					}

					//toggleDisableInputs(sendOrderFormInputs, true);

					if (!isSendOrderAvailable) {
						const data = {
							eshopId: getShoptetDataLayer('projectId'),
							billingAddress: billingAddress,
							deliveryAddress: deliveryAddress,
							customer: customer,
							items: items,
							totalPriceVat: roundCurrency(totalPriceVat),
							roundingPrice: Number((roundCurrency(totalPriceVat) - totalPriceVat).toFixed(2)),
						};

						console.log(data);

						fetch(`${twistoPayBaseRoute}order/check`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json'
							},
							body: JSON.stringify(data)
						})
						.then(response => response.json())
						.then(data => {
							const twistoLoaded = () => {
								if (!isTwistoCheckDone) {
									Twisto.check(data.payload, function(response) {
										if (response.status == 'accepted') {
											isTwistoCheckDone = true;

											const transactionId = response.transaction_id;
											isSendOrderAvailable = true;

											window.localStorage.setItem('twisto.transactionId', transactionId);

											setTimeout(() => {
												sendOrderForm.submit();
											}, 500);
										} else {
											const language = getShoptetDataLayer().language;
											const languageTexts = twistoPayTexts[language];

											let errorMessage = '';

											if (languageTexts) {
												errorMessage = twistoPayTexts[language].CantPayWithTwisto;
											} else {
												errorMessage = twistoPayTexts.en.CantPayWithTwisto;
											}

											window.localStorage.setItem('twisto.paymentChoose.error', errorMessage);

											isSendOrderProcesing = false;
											sendOrderSubmitBtn.classList.remove('disabled');
											sendOrderSubmitBtn.style.pointerEvents = 'auto';
											sendOrderSubmitBtn.innerHTML = sendOrderSubmitBtnOldHtml;

											window.location.replace(window.localStorage.getItem('twisto.paymentChoose.url'));
										}
									}, function() {
										isSendOrderProcesing = false;
										sendOrderSubmitBtn.classList.remove('disabled');
										sendOrderSubmitBtn.style.pointerEvents = 'auto';
										sendOrderSubmitBtn.innerHTML = sendOrderSubmitBtnOldHtml;

										console.error('TwistoPay - Twisto check error');

										const language = getShoptetDataLayer().language;
										const languageTexts = twistoPayTexts[language];

										let errorMessage = '';

										if (languageTexts) {
											errorMessage = twistoPayTexts[language].CantPayWithTwisto;
										} else {
											errorMessage = twistoPayTexts.en.CantPayWithTwisto;
										}

										showMessage(errorMessage, 'error')
									});
								}
							}

							const _twisto_config = {
								public_key: data.public_key,
								script: 'https://api.twisto.cz/v2/lib/twisto.js'
							};

							(function(e,g,a){function h(a){return function(){b._.push([a,arguments])}}var f=["check"],b=e||{},c=document.createElement(a);a=document.getElementsByTagName(a)[0];b._=[];for(var d=0;d<f.length;d++)b[f[d]]=h(f[d]);this[g]=b;c.type="text/javascript";c.async=!0;c.src=e.script; c.onload=twistoLoaded();a.parentNode.insertBefore(c,a);delete e.script}).call(window,_twisto_config,"Twisto","script");
						})
					} else {
						sendOrderSubmitBtn.classList.remove('disabled');
						sendOrderSubmitBtn.style.pointerEvents = 'auto';
						sendOrderSubmitBtn.innerHTML = sendOrderSubmitBtnOldHtml;
						isSendOrderProcesing = false;
					}
				}
			});
		}
	}
};

// Funkce ktera inicializuje logrocket na děkovací stránce
const initThanksPage = () => {
	const isThanksPage = getShoptetDataLayer().pageType === 'thankYou'; 

  if (isThanksPage) {
	  const thanksPage = document.querySelector('body');

		if (thanksPage) {
			const transactionId = window.localStorage.getItem('twisto.transactionId');

			if (window.localStorage.getItem('twisto.isSelected') && transactionId) {
				const language = getShoptetDataLayer().language;
				const languageTexts = twistoPayTexts[language];

				let message = '';

				if (languageTexts) {
					message = twistoPayTexts[language].OrderCompleting;
				} else {
					message = twistoPayTexts.en.OrderCompleting;
				}

				thanksPage.insertAdjacentHTML('beforeend', `
					<div class="twistoPayThanksPageOverlay">
						<h1>
							${message}
						</h1>
						<br>
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block;" width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
							<g>
								<path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
								<path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
								<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
							</g>
						</svg>
					</div>
				`);

				window.LogRocket && window.LogRocket.init('3ycbxn/shoptet-twistopay');

				const transactionId = window.localStorage.getItem('twisto.transactionId');

				fetch(`${twistoPayBaseRoute}order/insert`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						transactionId: transactionId,
						eshopId: getShoptetDataLayer('projectId'),
						orderId: getShoptetDataLayer().order.orderNo,
					})
				})
				.then(() => {
					const twistoPayThanksPageOverlay = document.querySelector('.twistoPayThanksPageOverlay');

					if (twistoPayThanksPageOverlay) {
						twistoPayThanksPageOverlay.classList.add('is-close');
					}

					window.localStorage.removeItem('twisto.isSelected')
					window.localStorage.removeItem('twisto.transactionId');
					window.localStorage.removeItem('twisto.billing');
					window.localStorage.removeItem('twisto.shipping');
					window.localStorage.removeItem('twisto.paymentChoose.url');
					window.localStorage.removeItem('twisto.paymentChoose.error');
				});
			}
		}
	}
}

const initTwisto = () => {
	initPaymentConfig();
	initPaymentChoosePageErrorMessage();
	initShipping();
	initBilling();
	//initLoadDiscountCupons();
	initLoadProductSettings();
	initSendOrderCheck();
	initThanksPage();


	const shippingEvent = new CustomEvent("ShoptetShippingMethodUpdated");
	const billingEvent = new CustomEvent("ShoptetBillingMethodUpdated");
	document.dispatchEvent(shippingEvent);
	document.dispatchEvent(billingEvent);
}

window.addEventListener('DOMContentLoaded', () => {
	initTwisto();
});
